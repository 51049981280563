import { useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import api from '../utils/api';
import getAppConfig from '../utils/getAppConfig';
import { NodeTypes } from '../generated/types';
import log from '../utils/logger';

export enum TrackDrupalStatisticAction {
  View = 'view',
}

export function getBundleForTypename(type: string) {
  switch (type) {
    case 'Activity':
      return 'activity';
    case 'Hotel':
      return 'hotel';
    case 'Poi':
      return 'poi';
    case 'Page':
      return 'page';
  }

  return null;
}

export interface UseTrackDrupalStatisticsParams {
  type: string;
  id: number;
  bundle: string;
  action: TrackDrupalStatisticAction;
}

export default async function useTrackDrupalStatistic({
  type,
  id,
  bundle,
  action = TrackDrupalStatisticAction.View,
}: UseTrackDrupalStatisticsParams) {
  const appConfig = getAppConfig();
  log(
    '[TrackDrupalStatistics]',
    `type=${type}`,
    `bundle=${bundle}`,
    `action=${action}`,
    `id=${id}`
  );

  const params = new URLSearchParams();
  params.append('type', type);
  params.append('bundle', bundle);
  params.append('action', action);
  params.append('id', id + '');
  params.append('frontend', appConfig.config.frontendName);

  try {
    await api.post('/s.php', params);
  } catch (e) {}
}
