import React from 'react';
import dynamic from 'next/dynamic';
import { NodeTypes } from '../generated/types';
import { getAppComponentByAppId } from '../utils/getAppComponent';

const NODE_MAPPING: {
  [index: string]: React.ComponentType<{
    data: any;
  }>;
} = {
  Page: dynamic(() => import('../components/Node/Page')),
  Hotel: getAppComponentByAppId(process.env.APP_ID!, 'Hotel'),
  Activity: getAppComponentByAppId(process.env.APP_ID!, 'Activity'),
  Poi: getAppComponentByAppId(process.env.APP_ID!, 'Poi'),
  News: dynamic(() => import('../components/Node/News')),
  Product: dynamic(() => import('../components/Node/Product')),
  Person: dynamic(() => import('../components/Node/Person')),
};

export default NODE_MAPPING;
