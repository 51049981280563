import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import Layout from '../components/Layout/Layout';
import SiteLoading from '../components/Common/SiteLoading';
import {
  InfolinoQueryQuery,
  Query,
  RouteQueryQuery,
  RouteQueryQueryVariables,
  SortOrder,
} from '../generated/types';
import { InfolinoQuery, RouteQuery } from '../queries';
import { ApiDataPageProps, NodeFragmentType } from '../types/global-types';
import NODE_MAPPING from '../config/node-mapping';
import { Box, Flex, Heading, Img, SimpleGrid } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import getGraphqlPageRoute from '../utils/getGraphqlPageRoute';
import getServerSidePropsData from '../utils/getServerSidePropsData';
import Container from '../components/Layout/Container';
import { NotFoundIcon } from '../config/icons';
import getAppConfig from '../utils/getAppConfig';
import useTrackDrupalStatistic, {
  getBundleForTypename,
  TrackDrupalStatisticAction,
} from '../hooks/useTrackDrupalStatistic';
import { Hotel as HotelType, WithContext, WebSite } from 'schema-dts';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import getSearchParams from '../utils/getSearchParams';
import log from '../utils/logger';
import SearchContext from '../context/SearchContext';
import EntityContext from '../context/EntityContext';
import renderMetatags from '../utils/renderMetatags';
import { IS_DEVELOPMENT, IS_SERVER } from '../config/constants';
import getInfolinoPath from '../utils/getInfolinoPath';
import InfolinoHeader from '../components/Layout/InfolinoHeader';
import Link from 'next/link';
import InfolinoContext from '../context/InfolinoContext';
import PARAGRAPH_MAPPING from '../config/paragraph-mapping';
import PageContext from '../context/PageContext';
import keyBy from 'lodash/keyBy';

const InfolinoHome = (data: InfolinoQueryQuery) => {
  return (
    <SimpleGrid
      spacing={4}
      columns={2}
      display={{
        base: 'grid',
        lg: 'none',
      }}
    >
      {data.infolino.menu.map((menuItem) => {
        return (
          <Link href={`/${menuItem.url}`} passHref key={menuItem.url}>
            <Box
              as="a"
              border={data.infolino.imageBorder ? '2px solid' : 'none'}
              borderColor={data.infolino.color}
            >
              <Img src={menuItem.image.derivative.url} />
              <Flex
                justifyContent="center"
                p={1}
                bg={data.infolino.color || 'red.400'}
                color="#fff"
                fontSize="sm"
                textAlign="center"
                wordBreak="break-word"
                __css={{
                  hyphens: 'auto',
                }}
              >
                {menuItem.title}
              </Flex>
            </Box>
          </Link>
        );
      })}
    </SimpleGrid>
  );
};

const BasicPageInfolino: React.FC<ApiDataPageProps<InfolinoQueryQuery>> = ({
  data,
}) => {
  const router = useRouter();
  const isFrontpage = router.asPath === '/';

  if (!data || !data.infolino) {
    return null;
  }

  return (
    <Container maxW="1140px">
      <InfolinoHeader data={data.infolino} />
      <InfolinoContext.Provider value={data.infolino}>
        <Box>
          {isFrontpage && <InfolinoHome {...data} />}
          <Box
            display={{
              base: isFrontpage ? 'none' : 'block',
              lg: 'block',
            }}
          >
            <Heading as="h1" my={9}>
              {data.infolino.page.title}
            </Heading>

            <Box className="ContentWrapper">
              {data.infolino.page.content?.map((data, i) => {
                if (PARAGRAPH_MAPPING[data.__typename]) {
                  const Component = PARAGRAPH_MAPPING[data.__typename];
                  return <Component key={data.id || i} data={data} />;
                }

                return null;
              })}
            </Box>
          </Box>
        </Box>
      </InfolinoContext.Provider>
    </Container>
  );
};

const BasicPageWeb: React.FC<ApiDataPageProps<RouteQueryQuery>> = ({
  data,
  form,
}) => {
  const router = useRouter();

  useEffect(() => {
    if (data.route?.entity) {
      const bundle = getBundleForTypename(data.route.entity.__typename);

      if (bundle && 'id' in data.route.entity) {
        useTrackDrupalStatistic({
          type: 'node',
          action: TrackDrupalStatisticAction.View,
          id: data.route.entity.id,
          bundle,
        });
      }
    }
  }, [data]);

  if (!data) return null;
  if (router.isFallback) {
    return <SiteLoading />;
  }

  const NodeComponent =
    data && data.route && data.route.entity
      ? NODE_MAPPING[data.route.entity.__typename]
      : null;

  const jsonLD = useMemo<WithContext<WebSite>>(() => {
    const appConfig = getAppConfig();

    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: appConfig.config.frontendName,
      url: appConfig.config.fullUrl,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${appConfig.config.fullUrl}/suche?query={search_term}`,
        'query-input': 'required name=search_term',
      },
    };
  }, []);

  const entity = data.route?.entity;
  let activeCategory = null;
  let isGroupSearch = false;

  if (entity?.__typename === 'Page') {
    activeCategory = entity.category;
    isGroupSearch = entity.isGroupSearch;
  }

  return (
    <Layout data={data}>
      <Box className="NodeWrapper">
        <Head>
          <script {...jsonLdScriptProps<WebSite>(jsonLD)} />
          {renderMetatags(data)}
        </Head>
        {NodeComponent && (
          <EntityContext.Provider
            value={{
              __typename: entity?.__typename,
              entity: entity as NodeFragmentType,
            }}
          >
            <SearchContext.Provider
              value={{
                activeCategory,
                form,
                isGroupSearch,
              }}
            >
              <PageContext.Provider
                value={{
                  adSlots: keyBy(data.adSlotTerms, 'key'),
                }}
              >
                <NodeComponent data={entity} />
              </PageContext.Provider>
            </SearchContext.Provider>
          </EntityContext.Provider>
        )}
        {!NodeComponent && (
          <Container>
            <Box marginY={12}>
              <Box textAlign="center">
                <NotFoundIcon boxSize="10em" />
              </Box>
              <Heading textAlign="center" as="h1">
                404 - Seite nicht gefunden
              </Heading>
            </Box>
          </Container>
        )}
      </Box>
    </Layout>
  );
};

const BasicPage: React.FC<
  ApiDataPageProps<RouteQueryQuery | InfolinoQueryQuery>
> = (props) => {
  const appConfig = getAppConfig();

  if (appConfig.config.frontendId === 'infolino') {
    return <BasicPageInfolino {...props} />;
  }

  return <BasicPageWeb {...(props as any)} />;
};

export const getServerSideProps: GetServerSideProps = async ({
  params,
  res,
  query,
  req,
  resolvedUrl,
}) => {
  const appConfig = getAppConfig();

  if (appConfig.config.frontendId === 'infolino') {
    const splittedPath = resolvedUrl.split('/');

    if (splittedPath[splittedPath.length - 1].indexOf('.') !== -1) {
      // This is a static request
      return {
        notFound: true,
      };
    }

    const path = getInfolinoPath(resolvedUrl, req.headers.host);

    return await getServerSidePropsData(res, InfolinoQuery, {
      path,
      frontend: 'Infolino',
    });
  }

  const route = getGraphqlPageRoute(params);

  if (route) {
    const searchParams = getSearchParams(query);
    log('[...path] SearchParams', JSON.stringify(searchParams, null, 2));

    const footerMain = appConfig.config.menuFooterMain || '';
    const activitiesInRegionFilter =
      appConfig.config.queryVariables?.hotel?.activitiesInRegion || null;

    const variables: RouteQueryQueryVariables = {
      route,
      frontend: appConfig.config.frontendName,
      menuFooter: appConfig.config.menuFooter,
      menuFooterMain: footerMain,
      menuMain: appConfig.config.menuMain,
      searchParameters: searchParams.params,
      includeFooterMain: Boolean(footerMain && footerMain !== ''),
      isMapQuery: Boolean(query && (query.karte === '' || query.karte === '1')),
      activitiesInRegionFilter,
      passTableParameters: {
        frontend: appConfig.config.frontendName,
        limit: 25,
        offset: 0,
        sort: {
          field: 'title',
          direction: SortOrder.Asc,
        },
        query: null,
        filter: null,
      },
      passMapParameters: {
        frontend: appConfig.config.frontendName,
      },
      passListMapParameters: {
        frontend: appConfig.config.frontendName,
        limit: 500,
      },
    };

    if (IS_DEVELOPMENT) {
      console.log(
        '[...path] GraphQL Query Vars',
        JSON.stringify(variables, null, 2)
      );
    }

    const data = (await getServerSidePropsData(res, RouteQuery, variables, {
      form: searchParams.form,
    })) as any;

    const redirect = data?.props?.data?.redirect;
    if (
      redirect &&
      redirect.statusCode &&
      redirect.destination !== '' &&
      redirect.destination !== route
    ) {
      return {
        redirect,
      };
    }

    if (!data.props.data.route || !data.props.data.route.entity) {
      // return {
      //     notFound: true,
      // };
      res.statusCode = 404;
    }

    return data;
  }

  return {
    props: {
      data: null,
    },
  };
};

export default BasicPage;
