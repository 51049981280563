import { createContext } from 'react';
import { AdSlotFragment } from '../generated/types';
import { NodeFragmentType } from '../types/global-types';

interface PageContextType {
  __typename?: string;
  adSlots?: Record<string, AdSlotFragment>;
}

const PageContext = createContext<PageContextType>(null);
export default PageContext;
