import { createContext } from 'react';
import { ActiveFilter, TermCategory } from '../generated/types';
import { ServerSideSearchFormProps } from '../types/global-types';

interface SearchContextType {
  activeCategory?: TermCategory;
  form?: ServerSideSearchFormProps;
  isGroupSearch?: boolean;
}

const SearchContext = createContext<SearchContextType>(null);
export default SearchContext;
