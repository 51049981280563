import { Query, RouteQueryQuery } from '../generated/types';
import { createElement } from 'react';

export default function renderMetatags(data: RouteQueryQuery): JSX.Element[] {
  const metatagComponents = [];

  if (data.route && data.route.entity) {
    const type = data.route.entity.__typename;

    if (
      type === 'Page' ||
      type === 'Activity' ||
      type === 'Hotel' ||
      type === 'Poi' ||
      type === 'Product' ||
      type === 'News' ||
      type === 'Person'
    ) {
      data.route.entity?.metatags?.forEach((metatag) => {
        if (metatag.attributes.name === 'title') {
          metatagComponents.push(
            <title key="title">{metatag.attributes.content}</title>
          );
        } else {
          const props: Record<string, string> = {};

          for (let [prop, propValue] of Object.entries(metatag.attributes)) {
            if (prop !== '__typename' && propValue) props[prop] = propValue;
          }

          props.key = metatag.attributes.name;

          metatagComponents.push(createElement(metatag.tag, props));
        }
      });
    }
  }

  return metatagComponents;
}
