import React, { FC, useState } from 'react';
import {
  Box,
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Img,
} from '@chakra-ui/react';
import Link, { default as NextLink } from 'next/link';
import { HamburgerIcon } from '@chakra-ui/icons';
import Head from 'next/head';
import { InfolinoQueryQuery } from '../../generated/types';
import MobileMenu from './MobileMenu';

type InfolinoHeaderProps = {
  data: InfolinoQueryQuery['infolino'];
};

const InfolinoHeader: FC<InfolinoHeaderProps> = ({ data }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Head>
        <title>{`${data.page.title} | ${data.hotel.title}`}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={() => setIsDrawerOpen(false)}
      >
        <DrawerOverlay zIndex={99}>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Heading as="h1" fontSize={24}>
                Navigation
              </Heading>
            </DrawerHeader>
            <DrawerBody>
              {data.menu.map((item) => {
                return (
                  <Flex
                    key={item.url}
                    cursor="pointer"
                    alignItems="center"
                    fontWeight="600"
                    mr="8"
                    paddingY={2}
                  >
                    <NextLink href={item.url} passHref>
                      <a>{item.title}</a>
                    </NextLink>
                  </Flex>
                );
              })}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Flex py={2} justifyContent="space-between" alignItems="center">
        <Box as={data.page.isHomepage ? 'h1' : 'h2'}>
          <Link href="/" passHref>
            <Box as="a">
              <Img src={data.logo.url} maxH="60px" />
            </Box>
          </Link>
        </Box>
        <Box>
          <IconButton
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            variant="unstyled"
            aria-label="Menu"
            fontSize="2xl"
            color={data.color || 'red.400'}
            icon={<HamburgerIcon />}
          />
        </Box>
      </Flex>
    </>
  );
};

export default InfolinoHeader;
